<template>

  <div class="announcement">
    <div class="info">
      <div class="info-item"><strong>Save the date:</strong></div>
      <div class="info-item">Samstag 23.11.2024</div>
      <div class="info-item">|</div>
      <div class="info-item">20:00</div>
    </div>
    <div class="info">AERA Gonzagagasse 11 1010 Wien</div>
    <div class="flyer spotlight-group" data-title="AERA 23.11.204" data-download="true">
      <a class="spotlight" href="/albums/20241123_aera/tgv_aera_2024_1.png">Flyer</a>
      <a class="spotlight" href="/albums/20241123_aera/tgv_aera_2024_2.png"></a>
    </div>
  </div>

</template>

<script setup>
// eslint-disable-next-line no-unused-vars
import {Spotlight} from 'spotlight.js';

console.log(Spotlight);

// eslint-disable-next-line no-unused-vars
const flyerUrl = "/albums/20241123_aera/tgv_aera_2024_1.png";
</script>

<style scoped>

.announcement {
  padding: 1rem;
  line-height: 1.0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1.4rem;
  color: var(--bright);
  z-index: 10;
  background: var(--dark);
  text-align: center;
  border-bottom: 1px solid #3f4653;
}

/* For bigger screens*/
@media (min-width: 768px) {
  .announcement {
    font-size: 1.6rem;
  }
}

.info {
  margin-bottom: 1.5rem;
}

.info-item {
  display: inline-block;
}

.info-item {
  margin-right: 1rem;
}

.flyer {
  background: var(--dark);
  font-size: 1.7rem;
  font-weight: bold;
  padding: 1rem;
  border-radius: 0.2rem;
  border-color: var(--bright);
  border-width: 1px;
  border-style: solid;
  display: inline-block;
}

.flyer a {
  color: var(--bright);
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.flyer:hover {
  text-decoration: none;
  background: #4d5667;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

</style>