<template>
  <header>
    <div id="title">
      <h1>Tin God's Village</h1>
      <h2>Rock Band</h2>
    </div>
    <HeroScrollButton/>
  </header>
</template>

<script>
import HeroScrollButton from "@/components/HeroScrollButton.vue";

export default {
  //https://1stwebdesigner.com/create-simple-parallax-scrolling-effect-in-hero-section-using-pure-css/
  // https://wiki.selfhtml.org/wiki/CSS/Tutorials/Hintergrund/Hero-Images
  name: "HeroSection",
  components: {HeroScrollButton}
}
</script>

<style scoped>
header {
  width: 100%;
  min-height: 100vh;
  position: relative;
  transform-style: preserve-3d;
}

header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      var(--dark)
  ), left 50% top 35% / cover no-repeat url('../assets/hero.webp');
  z-index: 0;
  transform: translateZ(-4px) scale(2);
}

#title {
  position: absolute;
  z-index: 1;
  top: 60%;
  padding: 0 15px;
  transform: translateZ(-2px) scale(1.5);
}

/* Default heading settings: for small screens*/
header h1 {
  font-size: 2rem;
  color: var(--bright);
  margin-bottom: 2vw;
  font-family: 'RockIt', sans-serif;
}

header h2 {
  font-size: 2rem;
  color: var(--bright);
}

/* For bigger screens*/
@media (min-width: 768px) {
  header h1,h2 {
    font-size: 3rem;
  }
}



</style>